import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

import { ImageTags } from '../models/image-tag';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Request } from 'express';

declare let gtag: Function;

@Injectable({ providedIn: 'root' })
export class SeoService {

  private url: string;
  public disableGaAutoLog: boolean = false;
  public activatedRoute: ActivatedRoute;


  constructor(
    private metaService: Meta,
    public titleService: Title,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object) {

      if (isPlatformBrowser(this.platformId)) {
        this.url = window.location.href;
      }
  }

  configure(title, tags: string = '', img: ImageTags = null) {

    let titleFormated = `${environment.siteTitle} - ${title}`;

    this.titleService.setTitle(titleFormated);

    this.metaService.removeTag('description');
    this.metaService.removeTag('keywords')

    this.metaService.updateTag({ name: 'description', content: titleFormated });
    this.metaService.updateTag({ name: 'keywords', content: tags });

    this.metaService.updateTag({ name: 'og:type', content: 'website' });
    this.metaService.updateTag({ name: 'og:title', content: titleFormated });
    this.metaService.updateTag({ name: 'og:site_name', content: environment.siteTitle });
    this.metaService.updateTag({ name: 'og:description', content: title });
    this.metaService.updateTag({ name: 'og:locale', content: 'pt_BR' });
    
    this.metaService.updateTag({ name: 'og:url', content: this.url});

    if (img) {
      this.metaService.updateTag({ name: 'og:image', content: img.image });
      this.metaService.updateTag({ name: 'og:image:secure_url', content: img.secure_url });
      this.metaService.updateTag({ name: 'og:image:type', content: img.type });
      this.metaService.updateTag({ name: 'og:image:width', content: img.width });
      this.metaService.updateTag({ name: 'og:image:height', content: img.height });
      this.metaService.updateTag({ name: 'og:image:alt', content: img.alt });
    }
  }

  clearAllTags() {
    this.removeTag('description');
    this.removeTag('keywords');
    this.removeTag('og:type');
    this.removeTag('og:title');
    this.removeTag('og:site_name');
    this.removeTag('og:description');
    this.removeTag('og:locale');
    this.removeTag('og:url');

    this.removeTag('og:image');
    this.removeTag('og:image:secure_url');
    this.removeTag('og:image:type');
    this.removeTag('og:image:width');
    this.removeTag('og:image:height');
    this.removeTag('og:image:alt');
  }

  removeTag(tag) {
    this.metaService.updateTag({ name: tag, content: '' });
    this.metaService.removeTag(`name='${tag}'`);
  }


  public setActivatedRoute(activatedRoute: ActivatedRoute) {
    this.activatedRoute = activatedRoute;
  }

  public setUpAnalytics(url) {
    console.warn(`Analytics disableGaAutoLog=${this.disableGaAutoLog}`);
    if (isPlatformBrowser(this.platformId)) {
      gtag('config', environment.googleAnalyticsId,
        {
          page_path: url
        }
      );
    }
  }
}
